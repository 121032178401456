import React from 'react';

import { ImageProxy } from 'lib/imageProxy';

import { ChannelMenu, Link } from './styles';

interface Channel {
  id: string;
  name: string;
  logo?: string;
}

function ChannelsMenuMobile({ channels }: { channels: Channel[] }) {
  return (
    <nav aria-label="Välj kanal">
      <ChannelMenu>
        {channels.map((channel) => (
          <li key={channel.id}>
            <Link href={`#${channel.id}`}>
              <img src={ImageProxy.url({ url: channel.logo, width: 200 })} alt={channel.name} />
            </Link>
          </li>
        ))}
      </ChannelMenu>
    </nav>
  );
}

export { ChannelsMenuMobile };
